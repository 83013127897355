<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑"
      @ok="handleSubmit"
    >
      <a-spin :spinning="loading">
        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 20 }"
          @submit="handleSubmit"
        >
          <a-form-item label="公告标题">
            <a-input
              v-decorator="['title', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入公告标题' },
                  { max: 200, message: '最多200个字符' },
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="公告内容">
            <wang-editor
              v-decorator="['content', {
                rules: [
                  { required: true, message: '请输入公告内容' },
                  { max: 65535, message: '最多65535个字符' },
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="立即发布">
            <a-checkbox
              v-decorator="['is_publish_immediately', { valuePropName: 'checked' }]"
            />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAnnouncementForm, updateAnnouncement } from '@/api/announcement'
import WangEditor from '@/components/Editor/WangEditor'

export default {
  name: 'EditAnnouncement',
  components: {
    WangEditor
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      form: this.$form.createForm(this, { name: 'announcement' }),
      roleOptions: [],
      isCanEditRole: false,
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      this.loading = true
      findAnnouncementForm(this.id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue(res.data)
        }
        this.loading = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateAnnouncement(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
