var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":"1000px","centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 3 },"wrapper-col":{ span: 20 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"公告标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入公告标题' },
                { max: 200, message: '最多200个字符' } ]
            }]),expression:"['title', {\n              normalize: this.$lodash.trim,\n              rules: [\n                { required: true, message: '请输入公告标题' },\n                { max: 200, message: '最多200个字符' },\n              ]\n            }]"}]})],1),_c('a-form-item',{attrs:{"label":"公告内容"}},[_c('wang-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:(['content', {
              rules: [
                { required: true, message: '请输入公告内容' },
                { max: 65535, message: '最多65535个字符' } ]
            }]),expression:"['content', {\n              rules: [\n                { required: true, message: '请输入公告内容' },\n                { max: 65535, message: '最多65535个字符' },\n              ]\n            }]"}]})],1),_c('a-form-item',{attrs:{"label":"立即发布"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_publish_immediately', { valuePropName: 'checked' }]),expression:"['is_publish_immediately', { valuePropName: 'checked' }]"}]})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }